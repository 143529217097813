.container-register { 
  height: 100vh;

  @media (min-width: 992px) {
    display: flex;

    .wizard { 
      width: 70%;
      display: flex;
      flex-direction: column;
    }
  }
}