.container { 
  background-color: #fff;
  width: 100%;
  padding: 2rem 1.5rem;
  max-width: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 4px;

  h1 { 
    font-weight: bold;
    text-align: center;
    font-size: 1.5rem;
  }

  p { 
    font-size: 1.015rem;
    margin: 1rem 0;
  }

  button { 
    width: 100%;
    max-width: 200px;
    margin-top: 2rem;
  }
}