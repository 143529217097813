.input-search { 
  position: absolute;
  z-index: 999;
  background-color: #fff;
  width: 100%;
  border: 1px solid #d7d7d7;

  height: auto;
  max-height: 180px;
  overflow-y: auto;
  
  .data-items { 
    padding: 14px 16px;
    border-bottom: 1px solid #d7d7d7;
    cursor: pointer;

    &:hover { 
      background-color: #fd7e14;
      color: #ffff;
    }
  }
}

.loader {
  position: absolute;
  height: 32px;
  width: 32px;
  z-index: 999;

  top: 12px;
  right: 10px;
}

.loader-line {
  width: 100%;
  height: 1.8px;
  bottom: 0;
  z-index: 999;
  position: absolute;
  overflow: hidden;
  background-color: #d7d7d7;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
}

.loader-line:before {
  content: "";
  position: absolute;
  left: -50%;
  height: 3px;
  width: 40%;
  background-color: #1b4756;
  -webkit-animation: lineAnim 1s linear infinite;
  -moz-animation: lineAnim 1s linear infinite;
  animation: lineAnim 1s linear infinite;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
}

@keyframes lineAnim {
  0% {
    left: -40%;
  }
  50% {
    left: 20%;
    width: 80%;
  }
  100% {
    left: 100%;
    width: 100%;
  }
}