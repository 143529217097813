.content-modal {
  background-color: #fff;
  width: 100%;
  padding: 2rem 1.5rem;
  margin: 0 2.5rem;
  max-width: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 4px;

  h1 {
    font-weight: bold;
    font-size: 1.75rem;
    margin-bottom: 0.5rem;
  }

  p {
    text-align: center;
    line-height: 1.4rem;
    margin: 0.5rem 0;
    font-size: 0.875rem;
  }

  button {
    margin-top: 1rem !important;
  }
}