.privacy-container { 
  zoom: 0.8;
  background-color: #fff;
  color: #767270;

  width: 100%;
  max-width: 80%;
  max-height: 90%;
  overflow-y: auto;

  display: flex;
  flex-direction: column;
  margin: 5rem 0;
  padding: 1.5rem;
  border-radius: 4px;

  h1, h2 { 
    font-size: 1.25rem;
    font-weight: 600;
  }

  h2, h4 { 
    margin: 1.25rem 0;
    font-size: 1.125rem;
  }

  p { 
    padding: 13px 0;
    line-height: 17pt;
    font-size: 10pt;
  }

  li { 
    line-height: 1.5rem;
  }

  ol { 
    padding-left: 0;
    margin: 0.5rem 0 0.5rem 2rem;
    list-style-type: decimal !important;

    li { 
      list-style: decimal !important;
    }
  }

  th { 
    padding: 15px 5px;
    display: table-cell;
    text-align: left;
    vertical-align: middle;
    border-radius: 2px;
  }

  .privacy__content {
    display: flex;
    flex-direction: column;
  }

  .privacy__logo {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      margin-bottom: 3rem;
    }
  }

  .privacy__box { 
    border: 1px solid #c0c0c0;
    margin: 0.5rem 0;
    padding: 1rem;
  }
}

.privacy-continue { 
  margin-top: 10rem;
}

.privacy__footer { 
  .checkbox-content { 
    margin: 4rem 0;
    display: flex;
    align-items: center;
    justify-content: center;
    
    .checkbox { 
      cursor: pointer;
      margin-bottom: 0.2rem;
      width: 100%;
      height: 25px;
      max-width: 25px;
      margin-right: 1rem;

      border: 1px solid #c4c4c4;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .checkbox-vibrate {
      animation: skew-y-shakeing 1s;
      border: 1px solid #f11;
    }

    .span-vibrate {
      animation: skew-y-shakeing 1s;
      color: #f11;
    }

    .selected {
      background-color: #198B54;

      span {
        font-size: 0.65rem;
        margin-top: 4px;
        color: #fff;
      }
    }

    .not-selected {
      span {
        display: none;
      }
    }

    span {
      font-size: 1rem;
    }
  }

  .footer-button {
    display: flex;
    justify-content: flex-end;
    border-top: 1px solid #6666;
    padding-top: 1rem;
    padding-right: 1rem;

    button { 
      background-color: transparent;
      color: #222;
      font-size: 1.125rem;
      margin-left: auto;
      outline: none;
      border: none;
  
      padding: 0.5rem 1.5rem;
      margin-left: auto;
    }
  }

}

@keyframes skew-y-shakeing{
  0% { transform: skewY(-1deg); }
  5% { transform: skewY(1deg); }
  10% { transform: skewY(-1deg); }
  15% { transform: skewY(1deg); }
  20% { transform: skewY(0deg); }
  100% { transform: skewY(0deg); }
}