.container-edit {
  @media (min-width: 992px) {
    height: 100vh;
    display: flex;

    .form-edit { 
      width: 70% !important;
    }
  }

  @media (max-width: 991.99px) { 
    padding-bottom: 1rem;
    .form-edit {    
      padding: 0 1.2rem;
    }
  }
}