.error-container { 
  display: flex;
  height: 100%;

  .error-content { 
    width: 100%;
    position: relative;

    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;

    text-align: center;
    color: #696767;

    .error-texts { 
      margin: auto;
    }

    h1 { 
      font-weight: 500;
      font-size: 6rem;
      margin-bottom: 2rem;
    }

    h2 { 
      font-weight: 500;
      font-size: 2rem;
    }

    span { 
      font-size: 1rem;
    }

    @media (max-width: 992px) {
      margin: 0 16px;

      h1 {
        font-weight: 500;
        font-size: 4rem;
        margin-bottom: 0.5rem;
      }
    
      h2 {
        font-weight: 500;
        font-size: 1.5rem;
      }
    
      span {
        font-size: 0.95rem;
      }
    }
  }
}