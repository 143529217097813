.resume-view { 
  position: relative;

  .footer {
    margin-bottom: 2rem;
  }

  @media (max-width: 991.99px) {
    height: 100%;

    .header-view { 
      display: none;
    }

    .resume-view-main {
      height: 100%;
      justify-content: space-between;
    }

    .resume-view-content { 
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
    
    .desktop { 
      display: none !important;
    }

    .footer { 
      margin-bottom: 4rem;

      .right-side { 
        .text { 
          @media (max-width: 992px) { 
            display: none !important;
          }
        }
      }
    }
  }

  @media (min-width: 992px) { 
    display: flex;
    height: 100%;

    .resume-view-main { 
      width: 100%;
    }

    .resume-view-content { 
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: calc(100% - 120px);

      @media (min-width: 992px) and (max-width: 1400px) {
        zoom: 0.8;
      }

      .divisor-resume-view {
        height: 70vh;
        width: 1px;
        background-color: #c4c4c4;
      }

      .resume-view-resumes { 
        margin: 2.25rem auto 0 auto;
        width: 40%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        h3 { 
          margin-bottom: 1rem;
        }
      }

      .swiper, .swiper-wrapper {
        max-width: 30rem;
      }
    }

    .footer {
      margin: 0 auto;
      width: 40%;
      display: flex;
      flex-direction: column;
      align-items: center;

      .resume-buttons { 
        width: 65%;
      }

      .right-side { 
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;

        .text {
          margin-top: 10rem;
          margin-bottom: 6rem;

          h3 {
            font-weight: bolder;
            font-size: 1.8rem;
            margin-bottom: 2rem;
            color: #E87737;
            font-style: italic;
          }

          p { 
            font-size: 1.125rem;
            line-height: 1.5rem;
          }
        }
      }
    }

    .select-photo { 
      justify-content: center;
      align-items: center;
      margin: 5rem 0;

      div { 
        position: relative;
        margin-bottom: 2rem;
        cursor: pointer;
      }

      .avatar { 
        height: 164px;
        width: 164px;
        border-radius: 100%;
      }

      .upload { 
        position: absolute;
        right: -60px;
        bottom: -70px;
      }
      
    }
  }

  @media (min-width: 992px) and (max-width: 1400px) {
    .select-photo { 
      zoom: 0.8;
    }

    .resume-selected {
      width: 32%;
    }
  }

  @media (min-width: 1400px) {
    .select-photo  { 
      margin: 3rem 0;
    }

    .right-side { 
      .text { 
        margin-top: 13rem !important;
        margin-bottom: 12rem !important;
      }
    }
  }
}