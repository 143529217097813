.form-header { 
  display: block;

  @media (min-width: 992px) {
    display: none !important;
  }
}

.container-header { 
  position: relative;
  width: 100%;
  height: 90px;
  display: flex;
  align-items: center;
  justify-content: center;
  
  @media (min-width: 768px) and (max-width: 1400px) {
    height: 100px;
    zoom: 0.8;
  }

  @media (max-width: 992px) { 
    display: none !important;
  }
  
  .header-view {
    img {
      margin-left: auto;
      margin-right: auto;
    }
  }
}