.lottie { 
  height: 25rem;
  width: 100%;
}

.finish-text {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 22%;
  margin-left: auto;
  margin-right: auto;

  span { 
    font-weight: 600;
    font-size: 1.125rem;

    @media (min-width: 768px) { 
      font-size: 1.25rem;
    }
  }
}

.finish-form {
  @media (max-width: 991.99px) { 
    height: calc(100vh - 10rem);
  }
}