.tips-container { 
  display: flex;
  flex-direction: column;
  justify-content: center;

  .tips-list { 
    margin: 0 auto;
    width: 60%;

    ul {
      list-style: none;
    }
  
    li:before {
      content: " ";
      display: block;
      float: left;
      width: 12px;
      height: 12px;
      border-radius: 100%;
      margin-top: 0.46rem;
      margin-right: 0.975rem;
      background-color: #666666;
    }
    
    li { 
      color: #666666;
      font-weight: 500;
      margin: 3rem 0;
      font-size: 1.125rem;
    }
  }

  @media (max-width: 768px) {
    max-width: 100%;
    justify-content: flex-start;

    .tips-list { 
      margin-right: 1rem;
      width: 100%;

      li {
        font-size: 1.1rem;
        max-width: 100%;
        margin-top: 1rem;
      }

      li:before {
        margin-top: 0.45rem;
      }
    }
  }

}