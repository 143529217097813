@keyframes pulse {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(232, 119, 55, 0.9);
  }

  70% {
    transform: scale(1.1);
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
  }

  100% {
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}

.container-sidebar { 
  width: 30%;
  display: flex;

  @media (max-width: 992px) {
    display: none !important;
  }
}

.sidebar-column { 
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.left-side-sidebar {
  border-right: 1px solid #c4c4c4;
  height: 100%;
  padding: 3rem 0.85rem;

  .top-sidebar { 
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    
    .pulse {
      position: absolute;
      background: #E87737;
      border-radius: 50%;
      bottom: 11px;
      left: 7px;
      margin: 10px;
      height: 10px;
      width: 10px;

      box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.2);
      transform: scale(1);
      animation: pulse 2s infinite;
    }

    .tips-content {   
      position: relative;

      .tips-icon {
        height: 2.5rem;
        width: 2.5rem;
        margin-bottom: 2rem;
      }

      .point {
        position: absolute;
        background-color: #E87737;
        padding: 4px;
        top: 5px;
        right: 10px;
        border-radius: 100%;
      }
    }

    .support-content {
      position: relative;

      .help-indicator { 
        position: fixed;
        left: 85px;
        top: 10.5rem;
      }

      .invalid-feedback::before { 
        left: -54.5px !important;
      }
      
      .invalid-feedback::after { 
        left: -54.5px !important;
        top: -3px !important;
      }
    }


    img { 
      cursor: pointer;
    }

  }

  .social-medias {
    justify-content: center;

    img { 
      height: 32px;
      width: 32px;
      margin: 5px 0;
    }

    .line { 
      width: 1px;
      background-color: #6A707E;
      height: 10rem;
      margin-top: 2rem;
    }
  }

  @media (max-width: 992px) {
    display: none;
  }
}

.right-side-sidebar {
  background-color: #1B4756;
  padding: 2.5rem 2rem;
  flex: 1;
  text-align: center;

  .logo {
    height: 2.8rem;
    object-fit: contain;
    width: auto;
  }

  .footer-text { 
    color: #fff;

    h3 { 
      font-weight: bold;
      margin-bottom: 1rem;
    }
  }
}



